<template>
  <!-- MultiStep Form -->
  <div class="center-wrapper">
    <Message v-if="message" :type="message.type" :message="message.message" :content="message.content" />
    <div class="tabs-bar">
       <Tabs :items="tabs" :tabIndex="getTabsIndex" :isAccount="true" @setActive="setActive"/>
    </div>
    <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <form v-if="tabIndex === 0" id="msform">
        <fieldset>
          <uploadFile @uploadFile="uploadFile"/>
        </fieldset>
      </form>
      <form v-if="tabIndex === 1" id="msform">
        <!-- progressbar -->
        <ul id="progressbar">
          <li :class="{'active': validate_1}">Personal Details</li>
          <li :class="{'active': validate_2}">EIN Proof</li>
          <li :class="{'active': validate_3}">Account Setup</li>
        </ul>
        <!-- fieldsets -->
        <fieldset v-if="activeIndex === 0">
          <PersonalDetails :userData="user" :personal_details="getPersonalDetails" @onNext="personDetails" />
        </fieldset>
        <fieldset v-else-if="activeIndex === 1">
          <EINProof :userData="user" :einDetails="getEinDetails" @onNext="EINProof" @onPrev="onPrev()" @skip="onSkip" />
        </fieldset>
        <fieldset v-else-if="activeIndex === 2">
          <Account :userData="user" :accountDetails="getAccountDetails" @onNext="accountDetails" @onPrev="onPrev()" @skip="onSkip" />
        </fieldset>
      </form>
      <div class="dme_link" v-if="tabIndex === 0">
        <p><a class="pointer" @click="onSkip">Skip</a></p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Tabs from '@/components/molecules/Tabs'
import uploadFile from '@/components/organisms/Form/uploadFile'
import PersonalDetails from '@/components/organisms/Form/PersonalDetails'
import EINProof from '@/components/organisms/Form/EINProof'
import Account from '@/components/organisms/Form/Account'
import Message from '@/components/molecules/Message'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    Tabs,
    uploadFile,
    PersonalDetails,
    EINProof,
    Account,
    Message
  },
  data() {
    return {
      tabs: ['Hardcopy download', 'Online submit details'],
      activeIndex: 0,
      isSubmit: false,
      tabIndex: 0,
      validate_1: false,
      validate_2: false,
      validate_3: false,
      personal_details: {},
      ein_proof: {},
      account_details: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    ...mapState(['message']),
    ...mapState('auth', ['user']),
    getTabsIndex () {
      return this.tabIndex
    },
    getPersonalDetails () {
      return this.personal_details
    },
    getEinDetails () {
      return this.ein_proof
    },
    getAccountDetails () {
      return this.account_details
    }
  },
  mounted() {
    const index = this.$route.query['form'] ?  this.$route.query['form'] : 0
    this.tabIndex = parseInt(index)
    this.updateRouter({ form: index })
  },
  metaInfo() {
    return {
      title: 'Credit Form'
    }
  },
  methods: {
    updateRouter(query) {
      this.$router.replace({ name: 'CreditForm', query: query  }).catch(() => {
        console.log('duplicate navigation')
      })
    },
    uploadFile(data) {
      console.log(data)
    },
    setActive(data) {
      this.tabIndex = data
      this.updateRouter({form: this.tabIndex})
    },
    onPrev() {
      this.activeIndex--
    },
    callToAPI(type, data) {
      data['users_permissions_user'] = this.userId
      if (this['user'] && this['user']['personal_detail'] && this['user']['personal_detail']['id']) {
        this.$store.dispatch('auth/updateDetails', {type: type, details: data, id: this['user']['personal_detail']['id']})
      } else {
        this.$store.dispatch('auth/personalDetails', {type: type, details: data})
      }
    },
    personDetails(data) {
      this.validate_1 = data.validate
      if (data.validate) {
        this.personal_details = data.details
        this.activeIndex = data.value
      }
    },
    EINProof(data) {
      this.validate_2 = data.validate
      if (data.validate) {
        this.ein_proof = data.details
        this.activeIndex = data.value
      }
    },
    accountDetails(data) {
      this.validate_3 = data.validate
      if (data.validate) {
        this.account_details = data.details
        let full_details = Object.assign(this.personal_details, this.ein_proof)
        full_details = Object.assign(full_details, this.account_details)
        this.callToAPI(3, full_details)
      }
    },
    gotoHome() {
      this.$router.push({ name: 'Home' })
    },
    async onSkip(value) {
      if (this.tabIndex === 0) {
        this.gotoHome()
      } else {
        if (value === 2) {
          await this.callToAPI(1, this.personal_details)
          await this.gotoHome()
        } else if (value === 3) {
          const details = Object.assign(this.personal_details, this.ein_proof)
          await this.callToAPI(2, details)
          await this.gotoHome()
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/login.sass'
.tabs-bar
  margin-bottom: 5em
.row
  padding: 2em 0 8em 0
#msform
  fieldset
    background: white
    border: 0 none
    border-radius: 0px
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4)
    padding: 35px 50px
    box-sizing: border-box
    width: 80%
    margin: 0 10%
    position: relative
    @media screen and (max-width: $md-breakpoint)
      width: 100%
      margin: 0
#progressbar
  margin-bottom: 30px
  overflow: hidden
  counter-reset: step

  li
    list-style-type: none
    text-transform: uppercase
    width: 33.33%
    float: left
    position: relative
    letter-spacing: 1px
    @include font-source('Poppins', 16px, 400, $primary-text)
    @media screen and (max-width: $md-breakpoint)
      font-size: 13px
    @media screen and (max-width: $phone-breakpoint)
      font-size: 10px

    &:before
      content: counter(step)
      counter-increment: step
      width: 24px
      height: 24px
      line-height: 26px
      display: block
      font-size: 12px
      color: $white
      background: $primary-text
      border-radius: 25px
      margin: 0 auto 10px auto

    &:after
      content: ''
      width: 100%
      height: 2px
      background: $primary-text
      position: absolute
      left: -50%
      top: 9px
      z-index: -1

    &:first-child:after
      content: none

    &.active
      &:before, &:after
        background: $orange
        color: white

.dme_link
  margin-top: 30px
  text-align: center

  a
    background: $white
    border: 0 none
    border-radius: 25px
    cursor: pointer
    padding: 5px 25px
    @include font-source('Poppins', 16px, 600, $orange)
    &:hover, &:focus
      background: #C5C5F1
      text-decoration: none
</style>