<template>
  <div :class="{'isAccount': isAccount}" class="tabs">
    <ul>
      <li v-for="(item, index) in items" :key="index" @click="setActive(index)" :class="{ 'active': isActive(index) }">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: [Number]
    },
    isAccount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeItem: 0
    }
  },
  watch: {
    tabIndex: function () {
      this.activeItem = this.tabIndex
    }
  },
  mounted () {
    this.activeItem = this.tabIndex
  },
  methods: {
    isActive (index) {
      return this.activeItem === index
    },
    setActive (index) {
      this.activeItem = index
      this.$emit('setActive', this.activeItem)
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs
  position: relative
  &.isAccount
    display: flex
    justify-content: center
    ul, li
      @media screen and (max-width: $md-breakpoint)
        flex-direction: row
        li
          font-size: 1em
  ul, li
    display: flex
    justify-content: center
    @media( max-width: $md-breakpoint)
      flex-direction: column
      width: fit-content
  ul
    li
      align-items: center
      justify-content: center
      cursor: pointer
      background-color: $white
      padding: 20px 5px 12px 5px
      margin-right: 30px
      @include font-source('Poppins', 22px, 600, $text-grey)
      &.active
        border-bottom: 2px solid $black1
        color: $black1
        font-weight: 500
</style>
