<template>
  <div class="wrap">
    <div class="personal-details">
      <h2 class="title">EIN Proof</h2>
      <h4 class="select">Please Select any field<sup>*</sup>
        <span v-if="errors.hasOwnProperty('company_type')" class="error">Field required</span>
      </h4>
      <div class="field">
        <div class="radio-btn" v-for="(item, index) in items" :key="index">
          <input type="radio" :id="item.name" :value="item.name" v-model="company_type">
          <label :for="item.name" class="pointer">{{ item['name'] }}</label>
        </div>
      </div>
      <h4>List full names, addresses and titles of all officers, partners or owners:</h4>
      <div v-for="(item, index) in EIN_proof" :key="index">
        <div class="tag" v-if="EIN_proof.length > 1">#{{ index + 1 }}</div>
      <div class="input-wrapper col-3">
        <div class="input-container noIcon">
          <label>Name<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['name']">
          <p v-if="errors.hasOwnProperty(`name${index}`)" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Position<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['Position']">
          <p v-if="errors.hasOwnProperty(`Position${index}`)" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Phone<sup>*</sup></label>
          <input class="input-field" type="number" v-model="item['Phone']">
          <p v-if="errors.hasOwnProperty(`Phone${index}`)" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper">
        <div class="input-container noIcon">
          <label>Address<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['Address']">
          <p v-if="errors.hasOwnProperty(`Address${index}`)" class="error">Field required</p>
        </div>
      </div>
      <div class="icons">
        <span class="add pointer" v-if="index === EIN_proof.length - 1" @click.prevent="addMore"><img src="@/assets/icons/plus.svg"></span>
        <span class="pointer" v-if="index > 0" @click.prevent="onRemove(index)"><img src="@/assets/icons/trash-bin.svg"></span>
      </div>
      </div>
      <div class="input-wrapper-button small-btn">
        <button class="pointer previous black" @click.prevent="$emit('onPrev')">Previous</button>
        <button class="orange pointer" @click.prevent="onNext">Next</button>
      </div>
    </div>
    <div class="dme_link">
      <p><a class="pointer" @click="onSkip">Skip</a></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userData: [Object],
    einDetails: [Object]
  },
  data() {
    return {
      isValidate: false,
      errors: {},
      company_type: false,
      items: [
        {
          id: 1,
          name: 'Organization'
        },
        {
          id: 2,
          name: 'Corporation'
        },
        {
          id: 3,
          name: 'Partnership'
        },
        {
          id: 4,
          name: 'Proprietorship'
        }
      ],
      EIN_proof: [
        {
          name: null,
          Position: null,
          Phone: null,
          Address: null
        }
      ]
    }
  },
  mounted() {
    if (this.userData && this.userData.personal_detail && Object.keys(this.userData.personal_detail).length > 0) {
      if (this.userData.personal_detail.EIN_proof && this.userData.personal_detail.EIN_proof.length > 0) {
        this.EIN_proof = this.userData.personal_detail.EIN_proof
      } else if (this.einDetails && Object.keys(this.einDetails).length > 0) {
        this.EIN_proof = this.einDetails.EIN_proof
      }
      if (this.userData.personal_detail.company_type) {
        this.company_type = this.userData.personal_detail.company_type
      } else if (this.einDetails && this.einDetails.company_type) {
        this.company_type = this.einDetails.company_type
      }
    }
  },
  methods: {
    addMore() {
      this.EIN_proof.push({
        name: null,
        Position: null,
        Phone: null,
        Address: null
      })
    },
    onSkip() {
      this.$emit('skip', 2)
    },
    onRemove(index) {
      this.EIN_proof.splice(index, 1)
    },
    validateFields() {
      this.errors = {}
      if (!this.company_type) {
        this.$set(this.errors, 'company_type', 'required')
      }
      this.EIN_proof.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (!this.EIN_proof[index][key]) {
            this.$set(this.errors, `${key}${index}`, 'required')
          }
        })
      })
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onNext() {
      this.isValidate = false
      if (this.validateFields()) {
        this.isValidate = true
      }
      this.$emit('onNext', { value: 2, validate: this.isValidate, details: {company_type: this.company_type, EIN_proof: this.EIN_proof} })
    }
  }
}
</script>
<style lang="sass" scoped>
@import '../../../sass/login.sass'
.wrap
  .personal-details
    .title
      @include font-source('Poppins', 20px, 700)
      margin-bottom: 2.5em
    .field
      display: flex
      justify-content: space-between
      margin-bottom: 1.5em
      @media screen and (max-width: $md-breakpoint)
        flex-direction: column
      .radio-btn
        display: flex
        align-items: center
        label
          @include font-source('Poppins', 13px, 400, $text-grey-2)
    .tag
      text-align: left
      margin-bottom: 2em
      @include font-source('Poppins', 15px, 400, $text-grey-2)
    h4
      text-align: left
      @include font-source('Poppins', 16px, 600)
      margin-bottom: 3em
      &.select
        margin-bottom: 1em
        sup
          color: $orange
          margin-left: 4px
        span.error
          color: $orange
          font-size: 13px
          margin-left: 10px
    .icons
      display: flex
      flex-direction: row
      justify-content: flex-end
      margin-bottom: 2.5em
      span
        &.add
          margin-right: 5px 
        img
          width: 35px
          max-width: 100
.dme_link
  margin-top: 30px
  text-align: center

  a
    background: $white
    border: 0 none
    border-radius: 25px
    cursor: pointer
    padding: 5px 25px
    @include font-source('Poppins', 16px, 600, $orange)
    &:hover, &:focus
      background: #C5C5F1
      text-decoration: none
</style>
