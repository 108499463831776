<template>
  <div class="wrap">
    <div class="personal-details">
      <h2 class="title">BANK INFORMATION</h2>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>Bank<sup>*</sup></label>
          <input class="input-field" type="text" v-model="bank_info['bank']">
          <p v-if="errors.hasOwnProperty('bankname')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Location<sup>*</sup></label>
          <input class="input-field" type="text" v-model="bank_info['location']">
          <p v-if="errors.hasOwnProperty('banklocation')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>Phone#<sup>*</sup></label>
          <input class="input-field" type="number" v-model="bank_info['phone']">
          <p v-if="errors.hasOwnProperty('phone')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Fax#<sup>*</sup></label>
          <input class="input-field" type="text" v-model="bank_info['fax']">
          <p v-if="errors.hasOwnProperty('bankfax')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>Contact Name#<sup>*</sup></label>
          <input class="input-field" type="text" v-model="bank_info['contact_name']">
          <p v-if="errors.hasOwnProperty('bankcontact_name')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Account#<sup>*</sup></label>
          <input class="input-field" type="text" v-model="bank_info['account']">
          <p v-if="errors.hasOwnProperty('bankaccount')" class="error">Field required</p>
        </div>
      </div>
      <h2 class="title">TRADE INFORMATION</h2>
      <div v-for="(item, index) in trade_info" :key="index">
      <div class="tag" v-if="trade_info.length > 1">#{{ index + 1 }}</div>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>Company<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['company']">
          <p v-if="errors.hasOwnProperty(`company${index}`)" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Location<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['location']">
          <p v-if="errors.hasOwnProperty(`location${index}`)" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-3">
        <div class="input-container noIcon">
          <label>Phone<sup>*</sup></label>
          <input class="input-field" type="number" v-model="item['phone']">
          <p v-if="errors.hasOwnProperty(`phone${index}`)" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Fax<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['fax']">
          <p v-if="errors.hasOwnProperty(`fax${index}`)" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Contact<sup>*</sup></label>
          <input class="input-field" type="text" v-model="item['contact']">
          <p v-if="errors.hasOwnProperty(`contact${index}`)" class="error">Field required</p>
        </div>
      </div>
      <div class="icons">
        <span class="add pointer" v-if="index === trade_info.length - 1" @click.prevent="addMore"><img src="@/assets/icons/plus.svg"></span>
        <span class="pointer" v-if="index > 0" @click.prevent="onRemove(index)"><img src="@/assets/icons/trash-bin.svg"></span>
      </div>
      </div>
      <div class="upload-sign">
        <div class="file">
          <p>Upload your signed
            <span v-if="errors.hasOwnProperty('signature')" class="error">Signature required</span>
          </p>
          <input type="file" name="file" @change="changeFile">
        </div>
        <div v-if="signatureImage && signatureImage[0]" class="image">
          <img v-if="signatureImage[0] && signatureImage[0].url" :src="getMediaURL(signatureImage[0], 'large')">
          <!--<span class="close pointer" @click="removeImage">
            <img src="@/assets/icons/close_icon.png">
          </span>-->
        </div>
      </div>
      <div>
      </div>
      <div class="input-wrapper-button small-btn">
        <button class="pointer previous black" @click.prevent="$emit('onPrev')">Previous</button>
        <button class="orange pointer" @click.prevent="onNext">Next</button>
      </div>
    </div>
    <div class="dme_link">
      <p><a class="pointer" @click="onSkip">Skip</a></p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import getMediaURL from '@/mixins/getMediaURL'
export default {
  mixins: [getMediaURL],
  props: {
    userData: [Object],
    accountDetails: [Object]
  },
  computed: {
    ...mapState('auth', ['signatureImage'])
  },
  data() {
    return {
      isValidate: false,
      errors: {},
      bank_info: {
        bank: null,
        location: null,
        phone: null,
        fax: null,
        contact_name: null,
        account: null
      },
      trade_info: [
        {
          company: null,
          location: null,
          phone: null,
          fax: null,
          contact: null
        }
      ]
    }
  },
  mounted() {
    if (this.userData && this.userData.personal_detail && Object.keys(this.userData.personal_detail).length > 0) {
      if (this.userData.personal_detail.bank_info && Object.keys(this.userData.personal_detail.bank_info).length > 0) {
        this.bank_info = this.userData.personal_detail.bank_info
      } else if (this.accountDetails.bank_info && Object.keys(this.accountDetails.bank_info).length > 0) {
        this.bank_info = this.accountDetails.bank_info
      }
      if (this.userData.personal_detail.trade_info && this.userData.personal_detail.trade_info.length > 0) {
        this.trade_info = this.userData.personal_detail.trade_info
      } else if (this.accountDetails.trade_info && Object.keys(this.accountDetails.trade_info).length > 0) {
        this.trade_info = this.accountDetails.trade_info
      }
    }
  },
  methods: {
    addMore() {
      this.trade_info.push({
        company: null,
        location: null,
        phone: null,
        fax: null,
        contact: null
      })
    },
    onSkip() {
      this.$emit('skip', 3)
    },
    onRemove(index) {
      this.trade_info.splice(index, 1)
    },
    // removeImage () {
    //   if (this.signatureImage && this.signatureImage.length > 0) {
    //     this.$store.dispatch('auth/deleteFile', this.signatureImage[0].id)
    //   }
    //   console.log('removeImage', this.signatureImage[0].id)
    // },
    changeFile(event) {
      let formData = null
      formData = new FormData()
      formData.append('files', event.target.files[0])
      this.$store.dispatch('auth/uploadFile', formData)
    },
    validateFields() {
      this.errors = {}
      if (!this.signatureImage && this.signatureImage <= 0) {
        this.$set(this.errors, 'signature', 'required')
      }
      Object.keys(this.bank_info).forEach((key) => {
        if (!this.bank_info[key]) {
          this.$set(this.errors, `bank${key}`, 'required')
        }
      })
      this.trade_info.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (!this.trade_info[index][key]) {
            this.$set(this.errors, `${key}${index}`, 'required')
          }
        })
      })
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onNext() {
      this.isValidate = false
      if (this.validateFields()) {
        this.isValidate = true
      }
      this.$emit('onNext', { value: 3, validate: this.isValidate, details: {bank_info: this.bank_info, trade_info: this.trade_info, signature:  this.signatureImage &&  this.signatureImage[0] ? this.signatureImage[0].id : null } })
    }
  }
}
</script>
<style lang="sass" scoped>
@import '../../../sass/login.sass'
.wrap
  .personal-details
    .title
      @include font-source('Poppins', 20px, 700)
      margin-bottom: 2.5em
    .tag
      text-align: left
      margin-bottom: 2em
      @include font-source('Poppins', 15px, 400, $text-grey-2)
    .icons
      display: flex
      flex-direction: row
      justify-content: flex-end
      span
        &.add
          margin-right: 5px 
        img
          width: 35px
          max-width: 100
  .upload-sign
    display: flex
    align-items: center
    margin-bottom: 3em
    @media screen and (max-width: $md-breakpoint)
      flex-direction: column
      align-items: baseline
    .file
      display: flex
      flex-direction: column
      align-items: baseline
      p
        margin-bottom: 2em
        span.error
          color: $orange
          font-size: 13px
          margin-left: 10px
    .image
      width: 200px
      height: 150px
      max-width: 100%
      background: $grey-2
      display: flex
      align-items: center
      justify-content: center
      position: relative
      &:hover
        .close
          display: flex
      @media screen and (max-width: $md-breakpoint)
        margin-top: 2em
        width: 100%
      .close
        display: none
        position: absolute
        background: rgba(0, 0, 0, 0.4)
        width: 100%
        height: 100%
        img
          position: absolute
          right: -25px
          top: -25px
      img
        max-width: 100%
        padding: 10px
.dme_link
  margin-top: 30px
  text-align: center

  a
    background: $white
    border: 0 none
    border-radius: 25px
    cursor: pointer
    padding: 5px 25px
    @include font-source('Poppins', 16px, 600, $orange)
    &:hover, &:focus
      background: #C5C5F1
      text-decoration: none
</style>
