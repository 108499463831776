<template>
  <div class="wrap">
    <div class="personal-details">
      <h2 class="title">Personal Details</h2>
      <div class="input-wrapper">
        <div class="input-container noIcon">
          <label>Legal Business Name<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.legal_business_name">
          <p v-if="errors.hasOwnProperty('legal_business_name')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>DBA<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.dba">
          <p v-if="errors.hasOwnProperty('dba')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Date Business Established<sup>*</sup></label>
          <input class="input-field" type="date" v-model="details.date_business_established">
          <p v-if="errors.hasOwnProperty('date_business_established')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper">
        <div class="input-container noIcon">
          <label>Address<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.address">
          <p v-if="errors.hasOwnProperty('address')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-3">
        <div class="input-container noIcon">
          <label>Province/State<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.state">
          <p v-if="errors.hasOwnProperty('state')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>City<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.city">
          <p v-if="errors.hasOwnProperty('city')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Postal Code<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.postalCode">
          <p v-if="errors.hasOwnProperty('postalCode')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-3">
        <div class="input-container noIcon">
          <label>FED Tax ID#<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.fed_tax_id">
          <p v-if="errors.hasOwnProperty('fed_tax_id')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Phone<sup>*</sup></label>
          <input class="input-field" type="number" v-model="details.phoneNumber">
          <p v-if="errors.hasOwnProperty('phoneNumber')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Fax<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.fax">
          <p v-if="errors.hasOwnProperty('fax')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper col-2">
        <div class="input-container noIcon">
          <label>A/P Contact<sup>*</sup></label>
          <input class="input-field" type="text" v-model="details.ap_contact">
          <p v-if="errors.hasOwnProperty('ap_contact')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Email Adress<sup>*</sup></label>
          <input class="input-field" type="email" v-model="details.emailAdress">
          <p v-if="errors.hasOwnProperty('emailAdress')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper-button small-btn center">
        <button class="orange pointer" @click.prevent="onNext">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userData: [Object],
    personal_details: [Object]
  },
  data() {
    return {
      isValidate: false,
      errors: {},
      details: {
        legal_business_name: null,
        dba: null,
        date_business_established: null,
        address: null,
        state: null,
        city: null,
        postalCode: null,
        fed_tax_id: null,
        phoneNumber: null,
        fax: null,
        ap_contact: null,
        emailAdress: null
      }
    }
  },
  mounted() {
    if (this.userData) {
      if (this.userData.personal_detail && Object.keys(this.userData.personal_detail).length > 0) {
        this.details.legal_business_name = this.userData.personal_detail.legal_business_name
        this.details.dba = this.userData.personal_detail.dba
        this.details.date_business_established =  this.userData.personal_detail.date_business_established
        this.details.address = this.userData.personal_detail.address
        this.details.state = this.userData.personal_detail.state
        this.details.city = this.userData.personal_detail.city
        this.details.fed_tax_id = this.userData.personal_detail.fed_tax_id
        this.details.fax = this.userData.personal_detail.fax
        this.details.ap_contact = this.userData.personal_detail.ap_contact
      } else {
        this.details = this.personal_details
      }
      this.details.phoneNumber = this.userData.phoneNumber
      this.details.postalCode = this.userData.pincode
      this.details.emailAdress = this.userData.email
    }
  },
  methods: {
    validateFields() {
      this.errors = {}
      Object.keys(this.details).forEach((key) => {
        if (!this.details[key]) {
          this.$set(this.errors, key, 'required')
        }
      })
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    onNext() {
      this.isValidate = false
      if (this.validateFields()) {
        this.isValidate = true
      }
      this.$emit('onNext', { value: 1, validate: this.isValidate, details: this.details })
    }
  }
}
</script>
<style lang="sass" scoped>
@import '../../../sass/login.sass'
.wrap
  .personal-details
    .title
      @include font-source('Poppins', 20px, 700)
      margin-bottom: 2.5em
</style>
